import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  ArrayField,
  Responsive,
  SimpleList,
  Filter,
  SelectInput,
  SingleFieldList,
  TextField,
  FunctionField,
  BooleanField,
  useRedirect,
} from "react-admin";
import LocalDateTimeField from "../components/LocalDateTimeField";
import { TimePeriod } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/time_pb";
import UserAvatar from "../components/UserAvatar";
import SquareImageField from "../components/SquareImageField";
import { BookingStatus } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/booking_pb";
import { CurrencyField } from "../components/CurrencyField";
import { IS_HD_RESOURCES_ENABLED } from "../config";

const timePeriodChoices = [
  {
    name: "Upcoming",
    id: TimePeriod.TIME_PERIOD_FUTURE,
  },
  {
    name: "Past",
    id: TimePeriod.TIME_PERIOD_PAST,
  },
  {
    name: "All",
    id: TimePeriod.TIME_PERIOD_UNSPECIFIED,
  },
];

const statusChoices = [
  {
    name: "Available",
    id: BookingStatus.BOOKING_STATUS_AVAILABLE,
  },
  {
    name: "Unavailable",
    id: BookingStatus.BOOKING_STATUS_UNAVAILABLE,
  },
];

const BookingsFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label=""
      source="timePeriod"
      choices={timePeriodChoices}
      allowEmpty={false}
      alwaysOn
    />
    <SelectInput label="Status" source="statusList" choices={statusChoices} />
  </Filter>
);

const list = (props) => {
  const redirect = useRedirect();

  if (IS_HD_RESOURCES_ENABLED) {
    redirect("/hotDeskBookings");
  }

  return (
    <List
      {...props}
      sort={{ field: "startsAt", order: "ASC" }}
      exporter={false}
      actions={null}
      filters={<BookingsFilter />}
      filterDefaultValues={{
        timePeriod: TimePeriod.TIME_PERIOD_FUTURE,
        statusList: BookingStatus.BOOKING_STATUS_AVAILABLE,
      }}
      bulkActionButtons={false}
      title="Hot Desk Bookings"
    >
      <Responsive
        small={
          <SimpleList
            linkType="show"
            primaryText={(record) => record.space.name}
            secondaryText={(record) =>
              `${new Date(record.startsAt).toLocaleDateString(
                navigator.languages,
                {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: record.space.timezone,
                }
              )} - ${new Date(record.endsAt).toLocaleTimeString(
                navigator.languages,
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: record.space.timezone,
                }
              )}`
            }
            tertiaryText={(record) =>
              `${record.membersList.length} ${
                record.membersList.length === 1 ? "member" : "members"
              }`
            }
          />
        }
        medium={
          <Datagrid rowClick="show">
            <SquareImageField source="space.image" label="" sortable={false} />
            <TextField label="Space" source="space.name" sortable={false} />
            <LocalDateTimeField
              source="startsAt"
              showTime
              locales={navigator.language}
              timezoneSource="space.timezone"
              options={{
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }}
            />
            <LocalDateTimeField
              source="endsAt"
              showTime
              locales={navigator.language}
              timezoneSource="space.timezone"
              options={{
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }}
            />
            <ArrayField source="attendees" label="Attendees" sortable={false}>
              <SingleFieldList linkType={false}>
                <UserAvatar source="user.profileImage" nameSource="user.name" />
              </SingleFieldList>
            </ArrayField>
            <NumberField
              source="cancelledMembersCount"
              label="Cancellations"
              sortable={false}
            />
            <NumberField
              source="capacity"
              label="Desks available"
              sortable={false}
            />
            <CurrencyField
              source="costPerHour"
              spaceIdSource="space.id"
              label="Price per hour per person"
              sortable={false}
            />
            <CurrencyField
              source="totalCost"
              spaceIdSource="space.id"
              label="Total estimated payout"
              sortable={false}
              style={{ fontWeight: "bold" }}
            />
            <FunctionField
              label="Available?"
              render={({ status }) =>
                status === BookingStatus.BOOKING_STATUS_AVAILABLE ? (
                  <BooleanField
                    record={{
                      status: true,
                    }}
                    label="Available?"
                    source="status"
                  />
                ) : (
                  <TextField
                    source="text"
                    style={{ fontWeight: "bold" }}
                    record={{
                      text: "Closed",
                    }}
                  />
                )
              }
            />
          </Datagrid>
        }
      />
    </List>
  );
};

export default list;

import { CountryCode } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/country_pb";
import { Currency } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/currency_pb";
import React from "react";
import {
  maxLength,
  required,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

let regionNamesInEnglish;
let currencyNamesInEnglish;

if (Intl && Intl.DisplayNames) {
  regionNamesInEnglish = new Intl.DisplayNames(["en"], { type: "region" });
  currencyNamesInEnglish = new Intl.DisplayNames(["en"], { type: "currency" });
}

// https://www.iban.com/country-codes
const countryCodes = Object.keys(CountryCode)
  .filter((a) =>
    [
      "COUNTRY_CODE_AD",
      "COUNTRY_CODE_AE",
      "COUNTRY_CODE_AR",
      "COUNTRY_CODE_AT",
      "COUNTRY_CODE_AU",
      "COUNTRY_CODE_BA",
      "COUNTRY_CODE_BE",
      "COUNTRY_CODE_BG",
      "COUNTRY_CODE_BH",
      "COUNTRY_CODE_BR",
      "COUNTRY_CODE_CA",
      "COUNTRY_CODE_CH",
      "COUNTRY_CODE_CO",
      "COUNTRY_CODE_CL",
      "COUNTRY_CODE_CY",
      "COUNTRY_CODE_CZ",
      "COUNTRY_CODE_DE",
      "COUNTRY_CODE_DK",
      "COUNTRY_CODE_EE",
      "COUNTRY_CODE_EG",
      "COUNTRY_CODE_ES",
      "COUNTRY_CODE_FI",
      "COUNTRY_CODE_FR",
      "COUNTRY_CODE_GB",
      "COUNTRY_CODE_GH",
      "COUNTRY_CODE_GR",
      "COUNTRY_CODE_HK",
      "COUNTRY_CODE_HR",
      "COUNTRY_CODE_HU",
      "COUNTRY_CODE_ID",
      "COUNTRY_CODE_IE",
      "COUNTRY_CODE_IL",
      "COUNTRY_CODE_IN",
      "COUNTRY_CODE_IS",
      "COUNTRY_CODE_IT",
      "COUNTRY_CODE_JP",
      "COUNTRY_CODE_KE",
      "COUNTRY_CODE_KR",
      "COUNTRY_CODE_KZ",
      "COUNTRY_CODE_LK",
      "COUNTRY_CODE_LV",
      "COUNTRY_CODE_MD",
      "COUNTRY_CODE_MK",
      "COUNTRY_CODE_MT",
      "COUNTRY_CODE_MX",
      "COUNTRY_CODE_MY",
      "COUNTRY_CODE_NL",
      "COUNTRY_CODE_NO",
      "COUNTRY_CODE_NZ",
      "COUNTRY_CODE_PE",
      "COUNTRY_CODE_PH",
      "COUNTRY_CODE_PK",
      "COUNTRY_CODE_PL",
      "COUNTRY_CODE_PR",
      "COUNTRY_CODE_PT",
      "COUNTRY_CODE_RO",
      "COUNTRY_CODE_RS",
      "COUNTRY_CODE_RW",
      "COUNTRY_CODE_SE",
      "COUNTRY_CODE_SG",
      "COUNTRY_CODE_SI",
      "COUNTRY_CODE_SK",
      "COUNTRY_CODE_TH",
      "COUNTRY_CODE_TR",
      "COUNTRY_CODE_TW",
      "COUNTRY_CODE_TZ",
      "COUNTRY_CODE_US",
      "COUNTRY_CODE_VN",
      "COUNTRY_CODE_ZA",
      "COUNTRY_CODE_ZM",
    ].includes(a)
  )
  .sort((a, b) => {
    if (regionNamesInEnglish) {
      return regionNamesInEnglish
        .of(a.replace("COUNTRY_CODE_", ""))
        .localeCompare(regionNamesInEnglish.of(b.replace("COUNTRY_CODE_", "")));
    }
    return a.localeCompare(b);
  });

export const countryOptions = countryCodes.reduce((acc, countryCode) => {
  const countryCodeString = countryCode.replace("COUNTRY_CODE_", "");
  return [
    ...acc,
    {
      id: CountryCode[countryCode],
      name: regionNamesInEnglish
        ? `${regionNamesInEnglish.of(countryCodeString)} (${countryCodeString})`
        : countryCodeString,
    },
  ];
}, []);

// https://www.iban.com/currency-codes
export const currencyCodes = Object.keys(Currency)
  .filter((a) =>
    [
      "CURRENCY_AED",
      "CURRENCY_ARS",
      "CURRENCY_AUD",
      "CURRENCY_BGN",
      "CURRENCY_BRL",
      "CURRENCY_CAD",
      "CURRENCY_CHF",
      "CURRENCY_CLP",
      "CURRENCY_COP",
      "CURRENCY_CZK",
      "CURRENCY_DKK",
      "CURRENCY_EGP",
      "CURRENCY_EUR",
      "CURRENCY_GBP",
      "CURRENCY_GHS",
      "CURRENCY_HKD",
      "CURRENCY_IDR",
      "CURRENCY_ILS",
      "CURRENCY_INR",
      "CURRENCY_ISK",
      "CURRENCY_JPY",
      "CURRENCY_KES",
      "CURRENCY_KRW",
      "CURRENCY_KZT",
      "CURRENCY_LKR",
      "CURRENCY_PKR",
      "CURRENCY_MAD",
      "CURRENCY_MXN",
      "CURRENCY_MYR",
      "CURRENCY_NOK",
      "CURRENCY_NZD",
      "CURRENCY_PHP",
      "CURRENCY_PLN",
      "CURRENCY_RON",
      "CURRENCY_RSD",
      "CURRENCY_SEK",
      "CURRENCY_SGD",
      "CURRENCY_THB",
      "CURRENCY_TRY",
      "CURRENCY_TWD",
      "CURRENCY_USD",
      "CURRENCY_VND",
      "CURRENCY_ZAR",
      "CURRENCY_ZMW",
    ].includes(a)
  )
  .sort((a, b) => {
    if (currencyNamesInEnglish) {
      return currencyNamesInEnglish
        .of(a.replace("CURRENCY_", ""))
        .localeCompare(currencyNamesInEnglish.of(b.replace("CURRENCY_", "")));
    }
    return a.localeCompare(b);
  });

export const currencyOptions = currencyCodes.reduce((acc, currencyCode) => {
  const currencyCodeString = currencyCode.replace("CURRENCY_", "");
  return [
    ...acc,
    {
      id: Currency[currencyCode],
      name: currencyNamesInEnglish
        ? `${currencyNamesInEnglish.of(
          currencyCodeString
        )} (${currencyCodeString})`
        : currencyCodeString,
      currencyCodeString,
    },
  ];
}, []);

const euCountryCodes = [
  CountryCode.COUNTRY_CODE_AT,
  CountryCode.COUNTRY_CODE_BE,
  CountryCode.COUNTRY_CODE_BG,
  CountryCode.COUNTRY_CODE_CH,
  CountryCode.COUNTRY_CODE_CY,
  CountryCode.COUNTRY_CODE_CZ,
  CountryCode.COUNTRY_CODE_DK,
  CountryCode.COUNTRY_CODE_EE,
  CountryCode.COUNTRY_CODE_FI,
  CountryCode.COUNTRY_CODE_FR,
  CountryCode.COUNTRY_CODE_DE,
  CountryCode.COUNTRY_CODE_GR,
  CountryCode.COUNTRY_CODE_HR,
  CountryCode.COUNTRY_CODE_HU,
  CountryCode.COUNTRY_CODE_IE,
  CountryCode.COUNTRY_CODE_IT,
  CountryCode.COUNTRY_CODE_LV,
  CountryCode.COUNTRY_CODE_LT,
  CountryCode.COUNTRY_CODE_LU,
  CountryCode.COUNTRY_CODE_MT,
  CountryCode.COUNTRY_CODE_NL,
  CountryCode.COUNTRY_CODE_NO,
  CountryCode.COUNTRY_CODE_PL,
  CountryCode.COUNTRY_CODE_PO,
  CountryCode.COUNTRY_CODE_PT,
  CountryCode.COUNTRY_CODE_RO,
  CountryCode.COUNTRY_CODE_SK,
  CountryCode.COUNTRY_CODE_SI,
  CountryCode.COUNTRY_CODE_ES,
  CountryCode.COUNTRY_CODE_SE,
];

const requiredValidator = required();
const USStateValidator = [required(), maxLength(2)];
const AUStateValidator = [required(), maxLength(3)];

export const getAccountFields =
  (classes) =>
    ({ formData, scopedFormData, getSource, disabled }) => {
      if (!scopedFormData) {
        return null;
      }
      switch (scopedFormData.currency) {
        // GBP
        case Currency.CURRENCY_GBP:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("routingNumber")}
                label="Sort Code"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // BRL
        case Currency.CURRENCY_BRL:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("branchCode")}
                label="Branch Code"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("detailsCnpj")}
                label="CNPJ (Brazilian individual tax ID number)"
                validate={requiredValidator}
              />
            </>
          );
        // CAD
        case Currency.CURRENCY_CAD:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("institutionNumber")}
                label="Institution Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("transitNumber")}
                label="Transit Number"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // CLP
        case Currency.CURRENCY_CLP:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("rut")}
                label="RUT (Chilean individual tax ID number)"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("phoneNumber")}
                label="Phone Number"
              />
            </>
          );
        // COP
        case Currency.CURRENCY_COP:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
              />
              <SelectInput
                className={classes.input}
                source={getSource("idDocumentType")}
                label="ID Document Type"
                validate={requiredValidator}
                choices={[
                  {
                    id: "CC",
                    name: "National Identity Card (Cédula de Ciudadanía)",
                  },
                  {
                    id: "CE",
                    name: "Foreigners Identification Card (Cédula de Extranjería)",
                  },
                  {
                    id: "TI",
                    name: "Youth Identity Card (Tarjeta de Identidad)",
                  },
                  {
                    id: "PAS",
                    name: "Passport (Pasaporte)",
                  },
                ]}
              />
              <TextInput
                className={classes.input}
                source={getSource("idDocumentNumber")}
                label="ID Document Number"
                validate={requiredValidator}
              />
              <TextField
                source="text"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Registered company address",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.line1")}
                label="Line 1"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.city")}
                label="City"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.zip")}
                label="Postcode"
                validate={requiredValidator}
              />
            </>
          );
        // AED, EGP, TRY
        case Currency.CURRENCY_AED:
        case Currency.CURRENCY_EGP:
        case Currency.CURRENCY_TRY:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("ibanNumber")}
                label="IBAN Number"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // INR
        case Currency.CURRENCY_INR:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("ifscCode")}
                label="IFSC Code"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // AUD
        case Currency.CURRENCY_AUD:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("businessNumber")}
                label="ABN (Business Number)"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bsbCode")}
                label="BSB Code"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextField
                source="text"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Registered company address",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.line1")}
                label="Line 1"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.city")}
                label="City"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.region")}
                label="State"
                validate={AUStateValidator}
                inputProps={{
                  maxLength: 3,
                  pattern: "[A-Z]*",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.zip")}
                label="Postcode"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // HKD
        case Currency.CURRENCY_HKD:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextField
                source="text"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Registered company address",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.line1")}
                label="Line 1"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.city")}
                label="City"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.zip")}
                label="Postcode"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // ILS
        case Currency.CURRENCY_ILS:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("ibanNumber")}
                label="IBAN Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextField
                source="text"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Registered company address",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.line1")}
                label="Line 1"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.city")}
                label="City"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.zip")}
                label="Postcode"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // SGD, IDR, MAD
        case Currency.CURRENCY_SGD:
        case Currency.CURRENCY_IDR:
        case Currency.CURRENCY_MAD:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
              />
            </>
          );
        // KRW
        case Currency.CURRENCY_KRW:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("email")}
                label="Email"
                type="email"
                validate={requiredValidator}
              />
            </>
          );
        // LKR, GHS, ZMW
        case Currency.CURRENCY_LKR:
        case Currency.CURRENCY_GHS:
        case Currency.CURRENCY_ZMW:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("branchCode")}
                label="Branch Code"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // KES
        case Currency.CURRENCY_KES:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
              />
            </>
          );
        // MXN
        case Currency.CURRENCY_MXN:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("clabe")}
                label="CLABE"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("detailsIdentificationNumber")}
                label="Identification number (optional)"
              />
            </>
          );
        case Currency.CURRENCY_NZD:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
              />
            </>
          );
        case Currency.CURRENCY_PLN:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
              />
              <TextInput
                className={classes.input}
                source={getSource("ibanNumber")}
                label="IBAN Number"
              />
            </>
          );
        // THB, PHP
        case Currency.CURRENCY_PHP:
        case Currency.CURRENCY_THB:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                label="Bank Code"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextField
                source="text"
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                record={{
                  text: "Registered company address",
                }}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.line1")}
                label="Line 1"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.city")}
                label="City"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("address.zip")}
                label="Postcode"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // ZAR
        case Currency.CURRENCY_ZAR:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bic")}
                label="BIC"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("phoneNumber")}
                label="Phone Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("email")}
                label="Email"
                type="email"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // JPY
        case Currency.CURRENCY_JPY:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />

              <TextInput
                className={classes.input}
                source={getSource("bankCode")}
                helperText="Four digit bank code"
                label="Bank Code"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("branchCode")}
                helperText="Three digit branch code"
                label="Branch Code"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // VND
        case Currency.CURRENCY_MYR:
        case Currency.CURRENCY_VND:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
                disabled={disabled}
              />
              <TextInput
                className={classes.input}
                source={getSource("bic")}
                label="BIC/SWIFT"
                validate={requiredValidator}
                disabled={disabled}
              />
            </>
          );
        // ARS
        case Currency.CURRENCY_ARS:
          return (
            <>
              <TextInput
                className={classes.input}
                source={getSource("accountNumber")}
                label="Account Number"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("taxId")}
                label="Tax ID - (CUIL/CUIT)"
                validate={requiredValidator}
              />
              <TextInput
                className={classes.input}
                source={getSource("email")}
                label="Email"
                type="email"
                validate={requiredValidator}
              />
            </>
          );
        // USD
        case Currency.CURRENCY_USD:
          // US
          if (scopedFormData.country === CountryCode.COUNTRY_CODE_US) {
            return (
              <>
                <TextInput
                  className={classes.input}
                  source={getSource("accountNumber")}
                  label="Account Number"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("abartn")}
                  label="Routing Number"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextField
                  source="text"
                  variant="subtitle1"
                  style={{ fontWeight: "bold" }}
                  record={{
                    text: "Registered company address",
                  }}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.line1")}
                  label="Line 1"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.city")}
                  label="City"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.region")}
                  label="State"
                  validate={USStateValidator}
                  inputProps={{
                    maxLength: 2,
                    pattern: "[A-Z]*",
                  }}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.zip")}
                  label="Zip"
                  validate={requiredValidator}
                  disabled={disabled}
                />
              </>
            );
          } else {
            return (
              <>
                <TextInput
                  className={classes.input}
                  source={getSource("accountNumber")}
                  label="Account Number"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("bic")}
                  label="BIC/Swift Code"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextField
                  source="text"
                  variant="subtitle1"
                  style={{ fontWeight: "bold" }}
                  record={{
                    text: "Registered company address",
                  }}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.line1")}
                  label="Line 1"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.city")}
                  label="City"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("address.zip")}
                  label="Zip"
                  validate={requiredValidator}
                  disabled={disabled}
                />
              </>
            );
          }
        // EU/World
        default:
          // Inside EU
          if (euCountryCodes.includes(scopedFormData.country)) {
            return (
              <>
                <TextInput
                  className={classes.input}
                  source={getSource("ibanNumber")}
                  label="IBAN Number"
                  validate={requiredValidator}
                  disabled={disabled}
                />
              </>
            );
          } else {
            // Outside EU
            return (
              <>
                <TextInput
                  className={classes.input}
                  source={getSource("accountNumber")}
                  label="IBAN Number"
                  validate={requiredValidator}
                  disabled={disabled}
                />
                <TextInput
                  className={classes.input}
                  source={getSource("bic")}
                  label="BIC"
                  validate={requiredValidator}
                  disabled={disabled}
                />
              </>
            );
          }
      }
    };

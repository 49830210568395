import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import IconMessage from "@material-ui/icons/MessageOutlined";
import {
  Button,
  CheckboxGroupInput,
  Edit,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { imageFieldFormatter } from "../common/image-field-formatter";
import { OpeningTimesInput } from "../components/OpeningTimesInput";
import SaveOnlyToolbar from "../components/SaveOnlyToolbar";
import { currencyOptions } from "../organisations/getAccountFields";
import { IS_HD_RESOURCES_ENABLED } from "../config";

const SpaceTitle = ({ record }) => {
  return <span>Editing {record ? `"${record.name}"` : ""}</span>;
};

const validateAddressLines = [required()];
const validateAdvancedAvailability = [required(), minValue(7), maxValue(365)];
const validateAutoresponder = [maxLength(250)];
const validateCapacity = [required(), minValue(0)];
const validateDescription = [required(), minLength(10), maxLength(700)];
const validateEntryInstructions = [maxLength(500)];
const validateSpaceName = [required(), minLength(3), maxLength(40)];
const validateWifiSpeed = [number(), minValue(0)];

const onWheel = (event) => {
  event.currentTarget.blur();
};

const SpaceEdit = ({ classes, ...props }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMenuOpen = useSelector((state) => state.admin.ui.sidebarOpen);

  const menuWidth = isXSmall ? 0 : isMenuOpen ? 370 : 70;
  const asideWidth = isSmall ? 0 : 215;
  const asideMargin = isSmall ? `` : " - 2em";

  const offset = ` - ${menuWidth + asideWidth}px${asideMargin}`;

  return (
    <Edit
      title={<SpaceTitle />}
      undoable={false}
      aside={isSmall ? null : <Aside />}
      {...props}
    >
      <TabbedForm
        toolbar={<SaveOnlyToolbar />}
        tabs={
          <TabbedFormTabs
            variant="scrollable"
            style={{
              width: `calc(100vw${offset})`,
            }}
          />
        }
        redirect={false}
      >
        <FormTab label="Basic Details">
          <TextInput
            source="name"
            autoCorrect="off"
            spellCheck="off"
            validate={validateSpaceName}
          />
          <TextInput
            multiline
            fullWidth
            source="description"
            validate={validateDescription}
          />
          <TextInput
            multiline
            fullWidth
            source="autoresponder"
            label="Autoresponse message when workers book your space"
            validate={validateAutoresponder}
          />
          <Divider fullWidth style={{ marginBottom: "1em" }} />
          {!IS_HD_RESOURCES_ENABLED && (
            <>
              <Typography variant="h6" gutterBottom>
                Availability Options
              </Typography>
              <NumberInput
                source="capacity"
                label="How many desks are available?"
                validate={validateCapacity}
                inputProps={{
                  onWheel,
                }}
                disabled
              />
              <NumberInput
                source="advancedAvailability"
                helperText="How many days in advance can this space be booked?"
                step={1}
                validate={validateAdvancedAvailability}
                inputProps={{
                  onWheel,
                }}
                disabled
              />
              <NumberInput
                source="minimumAdvanceNotice"
                helperText="How many minutes notice would you like for same day bookings?"
                inputProps={{
                  step: 15,
                  min: 0,
                  max: 60,
                  onWheel,
                }}
                disabled
              />
              <ChangeAvailability />
            </>
          )}
        </FormTab>
        {!IS_HD_RESOURCES_ENABLED && (
          <FormTab label="Pricing">
            <NumberInput
              disabled
              source="costPerHour"
              label="Price per hour"
              inputProps={{
                step: 0.01,
              }}
            />
            <SelectInput
              disabled
              source="currency"
              label="Currency"
              helperText="This will automatically be set when the bank account details are set"
              choices={currencyOptions}
              defaultValue={20}
            />
            <ChangePricing />
          </FormTab>
        )}
        <FormTab label="Address &amp; Location">
          <TextInput
            source="address.line1"
            label="Line 1"
            validate={validateAddressLines}
          />
          <TextInput source="address.line2" label="Line 2" />
          <TextInput
            source="address.city"
            label="City"
            validate={validateAddressLines}
          />
          <TextInput
            source="address.country"
            label="Country"
            validate={validateAddressLines}
          />
          <TextInput
            source="address.zip"
            label="Postcode"
            validate={validateAddressLines}
          />
          <TextInput
            multiline
            fullWidth
            source="address.instructions"
            label="Entry instructions"
            validate={validateEntryInstructions}
          />
        </FormTab>
        <FormTab label="Opening Times">
          <OpeningTimesInput />
          <ChangeOpeningTimes />
        </FormTab>
        <FormTab label="Images">
          <ImageInput
            source="imagesList"
            label="Space images"
            accept="image/*"
            multiple
          >
            <FunctionField
              render={(record) => {
                if (record.rawFile) {
                  return <ImageField record={record} />;
                }
                const optimisedRecord = imageFieldFormatter(record);
                return (
                  <ImageField
                    record={optimisedRecord}
                    source="url"
                    title="title"
                  />
                );
              }}
            />
          </ImageInput>
        </FormTab>
        <FormTab label="Wi-Fi Details">
          <TextInput source="wifiDetails.networkName" label="Wi-Fi network" />
          <TextInput source="wifiDetails.password" label="Wi-Fi password" />
          <TextInput
            multiline
            fullWidth
            source="wifiDetails.additionalDetails"
            label="Additional details"
          />
          <NumberInput
            source="downloadSpeed"
            label="Download speed (Mbps)"
            min={0}
            step={1}
            validate={validateWifiSpeed}
          />
          <NumberInput
            source="uploadSpeed"
            label="Upload speed (Mbps)"
            min={0}
            step={1}
            validate={validateWifiSpeed}
          />
        </FormTab>
        {!IS_HD_RESOURCES_ENABLED && (
          <FormTab label="Amenities">
            <ReferenceArrayInput
              source="amenitiesList"
              reference="spaceAmenities"
              label="Amenities"
            >
              <CheckboxGroupInput />
            </ReferenceArrayInput>
          </FormTab>
        )}
        <FormTab label="Rules">
          <ReferenceArrayInput
            source="rulesList"
            reference="spaceRules"
            label="Rules"
          >
            <CheckboxGroupInput />
          </ReferenceArrayInput>
          <TextInput multiline fullWidth source="additionalRules" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const ChangeOpeningTimes = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your opening times?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change your opening times, please get in touch with
            us below.
          </Typography>

          <Button
            label="Change opening times"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change my opening times for "${values.name}" (${values.id}) to:\n\n`
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

const ChangePricing = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your pricing?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change your pricing, please get in touch with us
            below.
          </Typography>
          <Button
            label="Change Pricing"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change my hot desk pricing for "${values.name}" (${values.id}) to:\n\n`
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

const ChangeAvailability = () => {
  const { values } = useFormState();

  if (!values?.name || !values?.id) {
    return null;
  }

  return (
    <Box component="span" m={2}>
      <Card mt={2}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Want to change your hot desk availability?
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you'd like to change your hot desk availability, please get in
            touch with us below.
          </Typography>

          <Button
            label="Change hot desk availability"
            alignIcon="right"
            color="primary"
            variant="outlined"
            onClick={() =>
              window.Intercom(
                "showNewMessage",
                `I'd like to change my hot desk availability for "${values.name}" (${values.id}) to:\n\n`
              )
            }
            style={{ marginTop: "20px" }}
          >
            <IconMessage />
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

const Aside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Which information will workers see?</Typography>
    <Typography variant="body2">
      <p>
        Workers will be able to see all the information provided{" "}
        <b>apart from Wi-Fi details</b>. These will only be revealed to workers
        30 minutes before a booking with you starts. Please provide as much
        information about your space as possible.
      </p>
    </Typography>
    <br />
    <Typography variant="h6">How to write a good description</Typography>
    <Typography variant="body2">
      <p>
        Keep the description short. Most workers will be using mobile devices so
        they're unlikely to read lots of text.
      </p>
      <p>Tell people about which features make your space stand out.</p>
      <p>
        Please do not advertise in your description. We work <b>exclusively</b>{" "}
        with companies so that their employees have access to high quality
        workspaces. Employees won't be looking to purchase your services
        directly.
      </p>
    </Typography>
  </div>
);

export default SpaceEdit;

import analytics from "./analytics";
import bankAccounts from "./bank-accounts";
import bookings from "./bookings";
import configuration from "./configuration";
import me from "./me";
import organisations from "./organisations";
import providers from "./providers";
import resourceBookings from "./resource-bookings";
import pendingResourceBookings from "./pending-resource-bookings";
import spaces from "./spaces";
import switchOrg from "./switch-org";
import users from "./users";
import resources from "./resources";
import reports from "./reports";
import { RESOURCE_TYPES } from "../config";

/**
 * Creates a new instance of the Desana API
 *
 * @param {string} url The API URL
 * @param {string} imageUploadsUrl The image uploads URL
 * @returns The API
 */
const raDataDesana = (url, imageUploadsUrl, openingTimesUrl) => {
  providers.init(url, imageUploadsUrl, openingTimesUrl);

  /**
   * Maps react-admin queries to Desana API
   *
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    switch (resource) {
      case "bankAccounts":
        return bankAccounts(type, params);
      case "spaceAmenities":
      case "spaceRules":
      case "spaceTypes":
      case "resourceTypes":
        return configuration(type, params, resource);
      case "bookings":
        return bookings(type, params);
      case "meetingRooms":
        return resources(type, params, RESOURCE_TYPES.MEETING_ROOM);
      case "privateOffices":
        return resources(type, params, RESOURCE_TYPES.PRIVATE_OFFICE);
      case "eventSpaces":
        return resources(type, params, RESOURCE_TYPES.EVENT_SPACE);
      case "hotDesks":
        return resources(type, params, RESOURCE_TYPES.HOT_DESK);
      case "hotDeskBookings":
        return resourceBookings(type, params, RESOURCE_TYPES.HOT_DESK);
      case "resourceBookings":
      case "meetingRoomBookings":
        return resourceBookings(type, params, RESOURCE_TYPES.MEETING_ROOM);
      case "privateOfficeBookings":
        return resourceBookings(type, params, RESOURCE_TYPES.PRIVATE_OFFICE);
      case "pendingResourceBookings":
        return pendingResourceBookings(type, params);
      case "spaces":
        return spaces(type, params);
      case "me":
        return me(type, params);
      case "users":
        return users(type, params);
      case "organisations":
        return organisations(type, params);
      case "dashboardAnalytics":
        return analytics(type, params, resource);
      case "switch-org":
        return switchOrg(type, params, resource);
      case "reports":
        return reports(type, params);
      default:
        throw new Error(`${resource} service is not implemented`);
    }
  };
};

export default raDataDesana;

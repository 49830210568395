import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from "react-admin";
import {
  ListBookingsRequest,
  GetBookingRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/booking_pb";
import { OrganisationMini } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/organisation_pb";
import { Sorter } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import providers from "./providers";
import * as storage from "../utils/storage";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { IS_HD_RESOURCES_ENABLED } from "../config";

const bookings = (type, params) => {
  switch (type) {
    case GET_LIST:
      return list(params);
    case GET_ONE:
      return get(params);
    case CREATE:
      throw new Error("Not implemented");
    // url = `${apiUrl}/${resource}`;
    // options.method = "POST";
    // options.body = JSON.stringify(params.data);
    // break;
    case UPDATE:
      throw new Error("Not implemented");
    // url = `${apiUrl}/${resource}/${params.id}`;
    // options.method = "PUT";
    // options.body = JSON.stringify(params.data);
    // break;
    case DELETE:
      throw new Error("Not implemented");
    // url = `${apiUrl}/${resource}/${params.id}`;
    // options.method = "DELETE";
    // break;
    // case GET_MANY: {
    //   const query = {
    //     filter: JSON.stringify({ id: params.ids })
    //   };
    //   url = `${apiUrl}/${resource}?${stringify(query)}`;
    //   break;
    // }
    // case GET_MANY_REFERENCE: {
    //   const { page, perPage } = params.pagination;
    //   const { field, order } = params.sort;
    //   const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify({
    //       ...params.filter,
    //       [params.target]: params.id
    //     })
    //   };
    //   url = `${apiUrl}/${resource}?${stringify(query)}`;
    //   break;
    default:
      throw new Error(
        `Unsupported Data Provider request type for bookings ${type}`
      );
  }
};

export default bookings;

const list = async (params) => {
  const { Bookings } = providers;
  const request = new ListBookingsRequest();
  const currentOrganisation = storage.get("currentOrganisation");
  const owner = new OrganisationMini();
  owner.setId(currentOrganisation.organisation.id);
  request.setOwner(owner);

  const currentOrg = storage.get("currentOrganisation");
  const viewBookingPermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
  ]);

  // If we don't have permission to edit bookings
  if (viewBookingPermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewBookingPermissions !== true) {
    request.setSpacesList(viewBookingPermissions);
  }

  if (params.pagination) {
    const { page, perPage } = params.pagination;
    request.setCursor(`${(page - 1) * perPage}`);
    request.setLimit(`${perPage}`);
  }

  if (params.filter) {
    if (params.filter.timePeriod) {
      request.setTimePeriod(params.filter.timePeriod);
    }

    if (params.filter.statusList) {
      request.setStatusList([params.filter.statusList]);
    }
  }

  const { field, order } = params.sort;
  const sorter = new Sorter();
  sorter.setKey(field.replace(/([A-Z])/g, "_$1").toLowerCase());
  sorter.setOrder(order.toLowerCase());
  request.setSortList([sorter]);

  const response = await Bookings.listBookings(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }
  return {
    total: response.getCount(),
    data: response
      .getResultsList()
      .map((result) => result.toObject())
      .map((b) => transformer(b)),
  };
};

const get = async (params) => {
  const { Bookings } = providers;
  const request = new GetBookingRequest();
  request.setId(params.id.split(",")[0]);
  const response = await Bookings.getBooking(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  const { resourceBookingId } = response.toObject();

  if (IS_HD_RESOURCES_ENABLED && resourceBookingId) {
    window.location.replace(`/hotDeskBookings/${resourceBookingId}`);
    return;
  }

  const b = response.getResult().toObject();

  return {
    data: transformer(b),
  };
};

const transformer = (booking) => {
  const membersList = booking.membersList.map((m) => ({
    ...m,
    cost: getCost(m, booking),
    timezone: booking.space.timezone,
    space: booking.space,
  }));

  return {
    ...booking,
    membersList,
    totalCost: membersList.reduce((acc, m) => acc + getCost(m, booking), 0),
    cancelledMembersCount: booking.membersList.filter((m) => !!m.cancelledAt)
      .length,
    attendees: membersList.filter(filterOutCancellations),
  };
};

const filterOutCancellations = (m) => (m.cancelledAt ? false : true);

const getCost = ({ refunded, cost, startsAt, hours }, { costPerHour }) => {
  if (refunded) {
    return 0;
  }

  if (cost) {
    return cost;
  }

  // All bookings made after May 2022 have a cost
  if (startsAt > "2022-05-01") {
    return cost;
  }

  return hours * costPerHour;
};

import { GET_ONE } from "react-admin";
import {
  GetSpaceViewsRequest,
  GetYearlyPayoutsRequest,
} from "@desanaio/public-hops-grpc-web/dist/desana/host/analytics/v1/analytics_pb";
import providers from "./providers";
import {
  DateRange,
  TimePeriod,
} from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/time_pb";
import { OrganisationMini } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/organisation_pb";
import { Paging, Sorter } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/request_options_pb";
import { ListBookingsRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/booking_pb";
import { ListResourceBookingsRequest } from "@desanaio/public-hops-grpc-web/dist/desana/host/booking/v1/resource_booking_pb";
import * as storage from "../utils/storage";
import { getSpacesForSpaceRole } from "../utils/auth";
import { SpaceAdminRole } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/authentication_pb";
import { BookingStatus } from "@desanaio/public-hops-grpc-web/dist/desana/type/v1/booking_pb";
import { IS_HD_RESOURCES_ENABLED, RESOURCE_TYPES } from "../config";

const analytics = (type, params, resource) => {
  switch (type) {
    case GET_ONE:
      return get(type, params, resource);
    default:
      throw new Error(
        `Unsupported Data Provider request type for configuration ${resource} ${type}`
      );
  }
};

export default analytics;

const get = async (type, params, resource) => {
  switch (resource) {
    case "dashboardAnalytics":
      return dashboardAnalytics(params);
    default:
      throw new Error(
        `Unsupported Data Provider request type for configuration ${resource} ${type}`
      );
  }
};

const dashboardAnalytics = async (params) => {
  const currentOrganisation = storage.get("currentOrganisation");

  const [spaceViewsResult, yearlyPayoutsResult, todaysOverviewResult] =
    await Promise.all([
      unthrowable(spaceViews(params, currentOrganisation)),
      unthrowable(yearlyPayouts(params, currentOrganisation)),
      unthrowable(
        IS_HD_RESOURCES_ENABLED
          ? todaysOverviewResourceBookings(params, currentOrganisation)
          : todaysOverview(params, currentOrganisation)
      ),
    ]);

  return {
    data: {
      id: params.id,
      spaceViews: spaceViewsResult,
      yearlyPayouts: yearlyPayoutsResult,
      todaysOverview: todaysOverviewResult,
    },
  };
};

const unthrowable = async (task) => {
  let result;
  let error;
  try {
    result = await task;
  } catch (err) {
    error = err;
  }

  return { result, error };
};

const spaceViews = async (params, organisation) => {
  const currentOrganisation = organisation.organisation;
  const service = providers.Analytics;
  const request = new GetSpaceViewsRequest();

  request.setOrganisationId(currentOrganisation.id);
  request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const dateRange = new DateRange();
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);
  dateRange.setLo(thirtyDaysAgo.toISOString());
  dateRange.setHi(now.toISOString());

  request.setDateRange(dateRange);

  const response = await service.getSpaceViews(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  const output = response.toObject();
  return output.result.bucketsList;
};

const yearlyPayouts = async (params, organisation) => {
  const currentOrganisation = organisation.organisation;
  const service = providers.Analytics;
  const request = new GetYearlyPayoutsRequest();

  request.setOrganisationId(currentOrganisation.id);
  request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const now = new Date();
  request.setYear(`${now.getFullYear()}`);

  const response = await service.getYearlyPayouts(request, {
    authorization: storage.get("desana-tkn", false),
  });
  if (!response) {
    throw new Error("No response returned from server");
  }

  const output = response.toObject();

  return {
    ...output,
    monthsList: output.monthsList.map((m) => ({
      ...m,
      name: `${m.name}|${m.estimated}`,
    })),
  };
};

// TODO (HD_TO_RESOURCE): once released, this function can replace todaysOverview
const todaysOverviewResourceBookings = async (params, organisation) => {
  const currentOrganisation = organisation.organisation;
  const { Bookings } = providers;
  const request = new ListResourceBookingsRequest();

  const owner = new OrganisationMini();
  owner.setId(currentOrganisation.id);
  request.setOwner(owner);
  request.setPaging(new Paging().setSize(20));
  request.setTimePeriod(TimePeriod.TIME_PERIOD_FUTURE);
  request.setStatusList([BookingStatus.BOOKING_STATUS_AVAILABLE]);
  request.setResourceTypesList([RESOURCE_TYPES.HOT_DESK]);
  const sorter = new Sorter().setKey("starts_at").setOrder("asc");
  request.setSortList([sorter]);

  const currentOrg = storage.get("currentOrganisation");
  const viewBookingPermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
  ]);

  // If we don't have permission to edit bookings
  if (viewBookingPermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewBookingPermissions !== true) {
    request.setSpacesList(viewBookingPermissions);
  }

  const response = await Bookings.listResourceBookings(request, {
    authorization: storage.get("desana-tkn", false),
  });

  if (!response) {
    throw new Error("No response returned from server");
  }

  const { count, resultsList } = response.toObject();
  const today = new Date();

  return {
    total: count,
    data: resultsList.reduce((bookingSlots, booking) => {
      const startsAt = new Date(booking.startsAt);
      if (!isToday(startsAt, today)) {
        return bookingSlots;
      }

      booking.membersList.forEach((m) =>
        bookingSlots.push({
          ...m,
          space: booking.space,
        })
      );

      return bookingSlots;
    }, []),
    nextBooking: resultsList[0],
  };
};

const todaysOverview = async (params, organisation) => {
  const currentOrganisation = organisation.organisation;
  const { Bookings } = providers;
  const request = new ListBookingsRequest();

  const owner = new OrganisationMini();
  owner.setId(currentOrganisation.id);
  request.setOwner(owner);
  request.setLimit(20);
  request.setTimePeriod(TimePeriod.TIME_PERIOD_FUTURE);
  request.setStatusList([BookingStatus.BOOKING_STATUS_AVAILABLE]);
  const sorter = new Sorter().setKey("starts_at").setOrder("asc");
  request.setSortList([sorter]);

  const currentOrg = storage.get("currentOrganisation");
  const viewBookingPermissions = getSpacesForSpaceRole(currentOrg, [
    SpaceAdminRole.SPACE_ADMIN_ROLE_VIEW_BOOKINGS,
    SpaceAdminRole.SPACE_ADMIN_ROLE_EDIT_BOOKINGS,
  ]);

  // If we don't have permission to edit bookings
  if (viewBookingPermissions === false) {
    throw new Error("Permission denied");
  }

  if (viewBookingPermissions !== true) {
    request.setSpacesList(viewBookingPermissions);
  }

  const response = await Bookings.listBookings(request, {
    authorization: storage.get("desana-tkn", false),
  });

  if (!response) {
    throw new Error("No response returned from server");
  }

  const { count, resultsList } = response.toObject();
  const today = new Date();
  return {
    total: count,
    data: resultsList.reduce((bookingSlots, booking) => {
      const startsAt = new Date(booking.startsAt);
      if (!isToday(startsAt, today)) {
        return bookingSlots;
      }

      booking.membersList.forEach((m) =>
        bookingSlots.push({
          ...m,
          space: booking.space,
        })
      );

      return bookingSlots;
    }, []),
    nextBooking: resultsList[0],
  };
};

const isToday = (someDate, today) =>
  someDate.getDate() === today.getDate() &&
  someDate.getMonth() === today.getMonth() &&
  someDate.getFullYear() === today.getFullYear();
